import { css } from '@emotion/core';
import resetCSS from '../lib/resetCSS';

const GlobalStyle = css`
  ${resetCSS};

  body {
    font-family: 'Inter', sans-serif;
    background-color: #fff;
  }
  ul {
    list-style: none;
  }

  a:hover {
    color: #3399ff;
  }

  button {
    outline: none;
  }

  .container {
    max-width: 1140px;
    margin: auto;
  }
`;

export default GlobalStyle;
