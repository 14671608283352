import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

const MainContainer = styled.div`
  height: 100%;
  /* background: url('/images/HeroImageToVideo.png'); */
  .hero {
    margin: 200px auto;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%auto;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;

    h1.heading {
      font-size: 48px;
      font-weight: 600;
      line-height: 60px;

      span {
        display: block;
      }
    }

    button {
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      margin: 1rem auto;
    }

    .no-credit-card {
      font-size: 14px;
    }
  }
`;

function Main2({ t, slug }) {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      file(relativePath: { eq: "HeroImageToVideo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const redirectToSignup = () => {
    window.location.href =
      '/sign-up?otherUrl=/workflow/marketing-templates&reload=true';
  };

  return (
    <MainContainer className="flex justify-center mb-8">
      <Img
        className="w-full h-full"
        fluid={data.file.childImageSharp.fluid}
        alt="Hero Backgound"
        style={{
          position: 'absolute',
          overflow: 'visible',
        }}
      />
      <div className="hero text-white mx-auto z-10">
        <h1 className="heading text-white">
          {t(`${slug}.title.s1`)}
          <span className="text-brandPrimary">{t(`${slug}.title.s2`)}</span>
        </h1>

        <button
          type="button"
          onClick={redirectToSignup}
          className="text-white bg-brandPrimary mx-auto"
        >
          {t(`heroCTA`)}
        </button>
        <p className="no-credit-card">
          <em>{t('no_credit_card')}</em>
        </p>
      </div>
    </MainContainer>
  );
}

export default Main2;
