import React from 'react';
import { Global } from '@emotion/core';
import { ThemeProvider } from 'emotion-theming';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';

import Header from './Header/Header';
import Footer from './Footer';
import GlobalStyle from './globalStyle';

function Layout({ children }) {
  const { language } = useI18next();
  return (
    <ThemeProvider theme={GlobalStyle}>
      <Global styles={GlobalStyle} />
      <Helmet htmlAttributes={{ lang: language }} />
      <Header />
      {children}
      <Footer />
    </ThemeProvider>
  );
}

export default Layout;
