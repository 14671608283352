import React from 'react';
// import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import SectionHeading from '../Headings/SectionHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const TemplateItem = styled.div`
  width: 30%;
  min-width: 250px;
  padding: 15px;
  margin: 15px;
  padding-bottom: 20px;

  border-radius: 5px;
  background-color: #f6f6f6;
  text-align: center;

  img {
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 17px;
    margin-bottom: 1.5rem;
  }

  a {
    background-color: #39f;
    color: #fff;
    font-size: 14px;
    padding: 0.5rem 2rem;
    border-radius: 4px;

    &:hover {
      color: #fff;
    }
  }
`;

const SingleTemplateCategory = ({ data, Image }) => {
  const { t } = useTranslation();
  return (
    <TemplateItem>
      <Img fluid={Image} alt={data.Name} loading="lazy" className="w-full" />

      <h3 className="mt-2">{data.Name}</h3>

      <a href={data.Link}>{t('view all')}</a>
    </TemplateItem>
  );
};

function LargeTemplateGrid({ widget }) {
  const images = [
    'https://d1nc6vzg2bevln.cloudfront.net/images/general/header-assets/brandintrooutro.jpg',
    'https://d1nc6vzg2bevln.cloudfront.net/images/general/header-assets/bitesizedads.jpg',
    'https://d1nc6vzg2bevln.cloudfront.net/images/general/header-assets/testimonials.jpg',
  ];

  return (
    <section className="container my-4">
      <div className="section-top text-center">
        <SectionHeading className="text-xl mb-8 px-2">
          Templates for
          <span className="text-brandPrimary"> each use-case</span>
        </SectionHeading>
        <p className="mb-4">{widget.SubHeading}</p>
      </div>

      <div className="flex flex-wrap justify-center">
        {widget.TemplateItem.map((template, index) => (
          <SingleTemplateCategory
            key={template.id}
            data={template}
            Image={template.Thumbnail?.childImageSharp?.fluid || images[index]}
          />
        ))}
      </div>
    </section>
  );
}

export default LargeTemplateGrid;
