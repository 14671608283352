module.exports = {
  siteTitle: 'Image To Video - Easy Photo to video Online Converter', // Navigation and Site Title
  siteTitleAlt: 'Image To Video - Easy Photo to video Online Converter', // Alternative Site title for SEO
  siteTitleShort: 'Image To Video', // short_name for manifest
  siteUrl: process.env.ROOT_URL || 'https://imagetovideo.com', // Domain of site. No trailing slash!
  sitehost: 'Imagetovideo.com',
  siteName: 'Imagetovideo',
  lang: 'en', // Language Tag on <html> element
  pathPrefix: '/',
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    'This service allows you convert online image to video, you can now create a video online from your pictures and music.',
  miniDescription: `
    This service allows you convert online image to video, you can now create a video online from your pictures and music.
    `,
  brand: {
    name: 'ImageToVideo',
    logo: 'https://cdn.imagetovideo.com/brand/logo.png',
    monogram: 'https://cdn.imagetovideo.com/brand/monogram.png',
  },
  keywords: [
    'photo',
    'image',
    'images',
    'photos',
    'video',
    'videos',
    'convert image to video online',
  ],
  author: 'Image ToV ideo', // Author for schemaORGJSONLD
  organization: 'Image To Video',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@imageTovideo', // Twitter Username
  ogSiteName: 'Image To Video', // Facebook Site Name
  ogLanguage: 'en_US',

  // Manifest and Progress color
  themeColor: '#4147DC',
  backgroundColor: '#231C42',

  // Social component
  twitter: 'https://twitter.com/ImageToVideo',
  twitterHandle: '@imageToVideo',
  github: 'https://github.com/invideoio',
  linkedin: 'https://www.linkedin.com/in/ImageToVideo/',
  rss: 'https://imagetovideo.com/blog/rss.xml',
};
