import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SectionHeading from '../Headings/SectionHeading';

const SingleItem = styled.div`
  width: 30%;
  min-width: 250px;
  margin: 15px;
  padding: 15px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #eee;
    border-color: #eee;
  }
`;

const SingleTool = ({ data, Image }) => {
  const url = new URL(data.Link);

  const { language, t } = useI18next();

  const link =
    language === 'en'
      ? data.Link
      : `https://${url.hostname}/${language}${url.pathname}`;

  return (
    <SingleItem className="text-center flex flex-col justify-between rounded-md border-gray-300 border-2">
      {/* <h3 className="my-2 font-semibold"></h3> */}
      <Img
        fluid={Image}
        alt={data.Name}
        className="mb-5 mx-auto w-full"
        loading="lazy"
      />

      <a
        className="bg-black text-white py-1 px-8 w-full mx-auto rounded text-sm"
        href={link}
      >
        {t(`tools.${data.Name.toLowerCase()}`, {
          defaultMessage: data.Name,
        })}
      </a>
    </SingleItem>
  );
};

function ToolsGrid({ widget }) {
  const images = [
    '/images/0.png',
    '/images/1.png',
    '/images/2.png',
    '/images/3.png',
    '/images/4.png',
    '/images/5.png',
  ];

  return (
    <div className="container">
      <SectionHeading className="text-center text-lg mb-8">
        Customize your <span className="text-brandPrimary">Video</span>
      </SectionHeading>

      <div className="flex flex-wrap justify-center">
        {widget.Tool.map((tool, index) => (
          <SingleTool
            key={tool.id}
            data={tool}
            Image={tool.Thumbnail?.childImageSharp?.fluid || images[index]}
          />
        ))}
      </div>
    </div>
  );
}

export default ToolsGrid;
