import styled from '@emotion/styled';
import React from 'react';

const MenuContainer = styled.div`
  .action-bar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-image: url('https://d1nc6vzg2bevln.cloudfront.net/website-assets/hamburger-menu.svg');
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center;
  }
  #menu {
    height: 100vh;
    width: 100vw;
    transition: transform 300ms ease-in-out;
    transform: translateX(-100vw);
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    z-index: 1;
    &.translateX {
      transform: translateX(0px);
    }
    .hamburger-content {
      width: 320px;
      height: 100%;
      background: #ffffff;
      padding: 0px 20px;
      overflow-y: scroll;

      .categories-dropdown,
      .explore-dropdown {
        margin: 0px -20px;
      }
      .menu-option-wrapper {
        padding: 10px 0px;
        .link-text {
          font-size: 18px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #39f;
          cursor: pointer;
          /* font-family: Barlow; */
          padding-right: 15px;
        }

        &:last-of-type {
          margin-bottom: 100px;
        }
      }
    }
    .fake-area {
      width: calc(100% - 320px);
      height: 100%;
      background: transparent;
    }
    .close-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .close {
        width: 24px;
        height: 60px;
        float: none;
        background-image: url('https://d1nc6vzg2bevln.cloudfront.net/website-assets/hamburger-close.svg');
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: right;
      }
    }
  }

  .no-login-actions-hamburger {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .nl-btn {
      border: none;
      outline: none;
      background: none;
      color: #39f;
      font-weight: 500;
      font-size: 16px;
      width: 130px;
      border-radius: 5px;
      line-height: 45px;
      &.login {
        // margin-right: 10px;
        border: solid 1px #39f;
      }
      &.signup {
        color: #ffffff;
        background: #39f;
        // padding: 6px 15px;
        // margin: 0;
      }
    }
  }

  .title-m,
  .sub-title-m {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #39f;
    cursor: pointer;
    /* font-family: Barlow; */
    padding: 10px 20px;
    margin: 0px;
    &.collapsed {
      .toggle-carret {
        transform: none;
      }
    }
  }

  .sub-title-m {
    font-size: 18px;
    font-weight: 500;
    padding-left: 30px;
    &.collapsed {
      .toggle-carret {
        transform: none;
      }
    }
  }
  .sub-title-choices-m {
    padding-left: 40px;
  }
  .tool-item-mini {
    padding: 8px 0;
    font-size: 18px;
    cursor: pointer;
    /* font-family: Barlow; */
    user-select: none;
    p {
      color: #39f;
      border-radius: 10px;
      font-weight: normal;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      margin: 0;
    }
  }
  .toggle-carret {
    width: 11px;
    background-image: url('https://d360zdw5tkn3j9.cloudfront.net/Template_Block_Assets/landing-page/arrow-copy-3x.png');
    height: 24px;
    float: right;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    transform: rotate(180deg);
  }

  .title-m.visible-xs:not(.collapsed) {
    background: #dfdfea;
  }
  .sub-title-m.visible-xs:not(.collapsed) {
    background: #e9e9f1;
  }
  .sub-title-choices-m.dont-collapse-sm.collapse {
    background: #f4f4f8;
  }
`;

function HamburgerMenu(props) {
  const [isMenuOpen, setisMenuOpen] = React.useState(false);
  const { t, isUserLoggedIn, userName } = props;

  const closeMenu = () => {
    setTimeout(() => {
      setisMenuOpen(false);
    }, 100);
  };

  const openMenu = () => {
    setTimeout(() => {
      setisMenuOpen(true);
    }, 400);
  };

  return (
    <MenuContainer className="hamburger-menu">
      <button
        className="navbar-toggler action-bar outline-none"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={openMenu}
      >
        <svg
          className="w-6 h-6 text-white"
          fill="#fff"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="https://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <div
        id="menu"
        className={isMenuOpen ? 'translateX' : ''}
        style={{ background: isMenuOpen ? 'rgba(0, 0, 0, 0.4)' : 'none' }}
      >
        <div className="hamburger-content">
          <div className="close-wrapper">
            <button
              aria-label="Close"
              type="button"
              className="close outline-none"
              onClick={closeMenu}
            />
          </div>
          {isUserLoggedIn ? (
            <div className="avatar-wrapper text-black" id="avatar-wrapper">
              <span id="username" className="mr-4">
                Hi {userName}
              </span>
              <a
                className="cta-tool text-white bg-brandPrimary border border-brandPrimary p-2 px-4 rounded"
                href="/workflow/marketing-templates"
              >
                Create
              </a>
            </div>
          ) : (
            <div className="no-login-actions-hamburger text-center">
              <a
                className="nl-btn login mx-2"
                href="/login"
                data-toggle="modal"
                data-target="#authModal"
              >
                {t('login')}
              </a>
              <a
                className="nl-btn signup mx-2"
                href="/sign-up"
                data-toggle="modal"
                data-target="#authModal"
              >
                {t('signup')}
              </a>
            </div>
          )}

          <div className="links-wrapper menu-option-wrapper mx-2">
            {/* <a href="/pricing" className="link-text">
              Pricing
            </a> */}
          </div>
        </div>
        <div className="fake-area" aria-hidden onClick={closeMenu} />
      </div>
    </MenuContainer>
  );
}

export default HamburgerMenu;
