import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// import HeaderDropDown from './header-dropdown';
import siteConfig from '../../../config/website.config';
import HamburgerMenu from './HamburgerMenu';

const HeaderContainer = styled.header`
  /* background-color: rgb(17, 17, 17); */

  font-size: 14px;
  height: 60px;

  .header-login {
    transition: all 0.2s ease;
    &:hover {
      color: #000;
      background-color: #fff;
    }
  }
  .header-signup {
    &:hover {
      color: #fff;
    }
  }

  @media screen and (max-width: 450px) {
    background-color: #000;
  }
`;

const NavBar = styled.nav`
  display: flex;

  .hamburger-menu {
    display: none;
  }

  #navbarContent {
    .cta-tool {
      &:hover {
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: 450px) {
    .hamburger-menu {
      display: block;
    }

    #navbarContent {
      display: none;
    }
  }
`;

const PromoBanner = styled.div`
  background-color: #3399ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  height: 50px;

  .promo-banner__text {
    color: #fff;
    margin: 0;
    font-size: 14px;
  }

  .close-banner {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: none;
      border-radius: 50%;
      font-size: 22px;
      background-color: transparent;
      width: 20px;
      height: 20px;
    }
  }

  .promo-banner__link {
    margin: 0 1rem;
    background-color: #fff;
    padding: 0.4rem 1rem;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    outline: none;
    color: rgb(17, 17, 17);
    text-decoration: none;
    text-align: center;
    width: auto;
    border: none;
  }
`;

function Header() {
  const [showBanner, setShowBanner] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userName, setUserName] = useState(null);

  const { t } = useTranslation();

  const checkIfUserLoggedIn = userObj => {
    if (userObj) {
      if (userObj.config === 'imagetovideo') {
        return true;
      }
    }
    return false;
  };

  const getUserInfo = () => {
    const user = localStorage.getItem(window.btoa('user'));
    return JSON.parse(user).first_name;
  };

  const decryptToken = token => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    const userAuthToken = localStorage.getItem('authToken');
    if (userAuthToken) {
      const userObj = decryptToken(userAuthToken);
      const isUserLogged = checkIfUserLoggedIn(userObj);

      /**
       * Store the Login status for Static Page usage.
       */
      localStorage.setItem('IsUserAuthenticated', isUserLogged);

      setIsUserLoggedIn(isUserLogged);
      if (isUserLoggedIn) {
        const userFirstName = getUserInfo();
        setUserName(userFirstName);
      }
    }
  }, [isUserLoggedIn]);

  const closeBanner = () => {
    setShowBanner(false);
  };

  /**
   * @function Redirect to URL with Auth check
   * @param url {string} URL to redirect
   * @param sessionRedirect {string} store a redirect sessionStorage Value
   */
  function redirectToURL(url, sessionRedirect) {
    sessionStorage.setItem('redirect', sessionRedirect);

    const isUserAuth = localStorage.getItem('IsUserAuthenticated');

    if (isUserAuth === 'false') {
      /**
       * Is user is not logged in then redirect them to sign-up page
       * following with redirect to intended page.
       */
      window.location.href = `/sign-up?otherUrl=${url}&reload=true`;
    } else {
      window.location.href = url;
    }
  }

  return (
    <HeaderContainer className="header pt-2 absolute top-0 px-8 mx-auto py-2 z-50 w-full">
      {showBanner && (
        <PromoBanner id="promo-banner" className="promo-banner">
          <p className="promo-banner__text">
            Get a lifetime membership for just $49.
          </p>
          <button
            onClick={() =>
              redirectToURL('/payments/checkout-lifetime', 'payment')
            }
            className="promo-banner__link"
          >
            Secure my spot
          </button>
          <div className="close-banner">
            <button onClick={closeBanner}>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </PromoBanner>
      )}

      <NavBar className="navbar sticky-top navbar-expand-lg container flex justify-between items-center">
        <div className="right-header flex items-center">
          <HamburgerMenu
            t={t}
            isUserLoggedIn={isUserLoggedIn}
            userName={userName}
          />
          <a className="navbar-brand logo-default h-auto mx-4 ml-6" href="/">
            <picture className="h-auto">
              <source
                media="(max-width: 768px)"
                srcSet={siteConfig.brand.monogram}
              />
              <source srcSet={siteConfig.brand.logo} />
              <img className="h-8 m-0" src={siteConfig.brand.logo} alt="Logo" />
            </picture>
          </a>
          {/* <HeaderDropDown /> */}
        </div>
        <div className="collapse navbar-collapse flex" id="navbarContent">
          <div className="header-right flex items-center">
            {/* <a className="text-white m-2" href="/pricing">
              Pricing
            </a> */}
            {isUserLoggedIn ? (
              <div className="avatar-wrapper text-white" id="avatar-wrapper">
                <span id="username" className="mr-4">
                  Hi {userName}
                </span>
                <a
                  className="cta-tool text-white bg-brandPrimary border border-brandPrimary p-2 px-4 rounded"
                  href="/workflow/marketing-templates"
                >
                  Create
                </a>
              </div>
            ) : (
              <>
                <a
                  href="/login"
                  className="header-login text-white px-8 py-1 rounded border-white border m-2"
                >
                  {t('login')}
                </a>
                <a
                  href="/sign-up"
                  className="header-signup text-white px-8 py-1 rounded bg-brandPrimary border-none m-2"
                >
                  {t('signup')}
                </a>
              </>
            )}
          </div>
        </div>
      </NavBar>
    </HeaderContainer>
  );
}

export default Header;
