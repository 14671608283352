import styled from '@emotion/styled';

const MainWrapper = styled.main`
  section {
    padding: 100px 0;
  }
  .row {
    /* align-items: center; */
  }
  .breadcrumb-wrap {
    margin: 10px 0;
    .breadcrumb {
      text-transform: capitalize;
      display: flex;
      flex-wrap: wrap;
      display: -ms-flexbox;
      -ms-flex-wrap: wrap;
      padding: 0;
      list-style: none;
      background-color: white;

      li {
        display: inline;
        font-size: 15px;
        color: #2774fc;
        cursor: default;
        a {
          color: #000;
          font-size: 15px;
        }
        i {
          color: #000;
          font-weight: 900;
          padding: 0 5px;
        }
      }
    }
  }

  .main-section {
    position: relative;
    padding-top: 0;
    padding-bottom: 100px;
    border-bottom: 1px solid #ebeaeb;

    .content {
      padding: 50px 0 5px;
      color: #000;
      .title {
        @include font(52px, #000, 1.25, 0.5, 600);
      }
      .description {
        @include font(18px, #000, 1.7, 0.5, normal);
        @extend .p-width-adjust;
        margin-top: 15px;
      }
      .action {
        margin: 35px 0;
        h3 {
          @extend .single-make-btn;
        }
      }
    }
  }

  .brands {
    padding: 65px 0 45px 0;
    border-bottom: 1px solid #ebeaeb;
    .custom-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .client {
        img {
          display: block;
          margin: 0 auto 20px;
          max-height: 30px;
        }
      }
      .score-wrapper {
        .label {
          @include font(20px);
        }
        .value {
          @include font(34px, #2162a3);
        }
      }
    }
  }
`;

export default MainWrapper;
