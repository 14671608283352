import React from 'react';
import { Helmet } from 'react-helmet';

import siteConfig from '../../config/website.config';

function SEO({ t, seo, children, slug, lang }) {
  return (
    <Helmet>
      <title>{lang === "en" ? seo.Title : t(`${slug}.seo.title`) || siteConfig.siteTitle}</title>
      <meta
        name="description"
        content={lang === "en" ?  seo.Description : t(`${slug}.seo.description`) || siteConfig.siteDescription}
      />
      <meta
        name="keywords"
        content={lang === "en" ? seo.Keywords : t(`${slug}.seo.keywords`) || siteConfig.keywords}
      />
      {seo.CanonicalURL && <link rel="canonical" href={seo.CanonicalURL} />}

      {seo.RobotFollow ||
        (seo.RobotIndex && (
          <meta
            name="robots"
            content={`${seo.RobotFollow || ''} ${seo.RobotIndex || ''}`}
          />
        ))}

      {Array.isArray(seo.Meta) &&
        seo.Meta.map(meta => (
          <meta key={meta.id} name={meta.Name} content={meta.Content} />
        ))}

      {Array.isArray(seo.Hreflang) &&
        seo.Hreflang.map(hreflink => (
          <link
            key={hreflink.Lang}
            rel="alternate"
            hrefLang={hreflink.Lang === "xdefault" ? "x-default" : hreflink.Lang}
            href={hreflink.Link}
          />
        ))}

      {children}
    </Helmet>
  );
}

export default SEO;
