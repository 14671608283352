import React from 'react';
// import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faFacebookF,
//   faTwitter,
//   faTumblr,
//   faYoutube,
// } from '@fortawesome/free-brands-svg-icons';
import { useI18next } from 'gatsby-plugin-react-i18next';

import siteConfig from '../../config/website.config';

const toTopFromBottom = keyframes`
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
`;

const FooterContainer = styled.footer`
  display: block;
  position: relative;
  background-color: #171717;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #fff;
  .footer-content {
    padding: 60px 0 40px 0;

    .widget {
      .widget-title {
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 600;
      }

      h4 {
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 24px;
        margin-bottom: 20px;
        color: #fff;
      }

      ul {
        li {
          a {
            font-weight: 400;
            color: #fff;
          }
        }
      }
    }

    h5 {
      font-size: 1em;
      line-height: 1.85714286em;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
`;

const FooterCopyRight = styled.div`
  min-height: 80px;
  padding: 30px 0;
  background-color: #171717;
  font-size: 16px;
  color: #fff;

  .foooter__flag {
    img {
      width: 26px;
      height: 26px;
    }
  }

  .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      &:first-of-type {
        a {
          margin-left: 0px;
        }
      }
      a {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0px 5px;
        &:hover {
          text-decoration: none;
        }
      }
      &.social-tumblr {
        a {
          background-color: #426d9b;
        }
      }
      &.social-facebook {
        a {
          background-color: #5d82d1;
        }
      }
      &.social-twitter {
        a {
          background-color: #40bff5;
        }
      }
      &.social-youtube {
        a {
          background-color: #ef4e41;
        }
      }
      &:hover {
        a {
          svg {
            animation: ${toTopFromBottom} 0.3s forwards;
          }
        }
      }
    }
  }

  @media screen and (max-width: 560px) {
    .copyright-text {
      flex-direction: column-reverse;

      div {
        margin: 0.5rem 0;
      }
    }
  }
`;

function Footer() {
  const { changeLanguage, t } = useI18next();

  const redirectToLang = e => {
    const lang = e.target.getAttribute('data-lang');
    if (lang) {
      changeLanguage(lang);
      // window.location.href = lang === 'en' ? `/` : `/${lang}`;
    }
  };

  return (
    <FooterContainer id="footer" className="footer mt-8">
      {/* <div className="footer-content">
        <div className="container">
          <div className="flex flex-wrap justify-around">
            <div className="w-1/3 p-8">
              <p>
                <Link to="/">
                  <img
                    style={{ maxWidth: '250px' }}
                    className="img-fluid"
                    src={siteConfig.brand.logo}
                    alt={siteConfig.brand.name}
                  />
                </Link>
              </p>
              <p>
                Create eye-catching, high-definition video intros with our free
                tool. Absolutely no experience or tools are required to start
                creating your dream video with our video maker.
              </p>
            </div>
            <div className="col-12 col-md-6 col-xl-5">
              <div className="widget">
                <h4>{siteConfig.brand.name} For</h4>
                <div className="flex flex-column flex-xl-row">
                  <ul className="mr-5 my-2">
                    <li className="link">
                      <Link to="https://invideo.io/make/intro-maker/">
                        Intro Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/youtube-intro-maker/">
                        YouTube Intro Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/promo-video-maker/">
                        Promo Video Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/online-video-editor/">
                        Online Video Editor
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/ad-maker/">
                        Ad Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/instagram-video-editor/">
                        Instagram Video Editor
                      </Link>
                    </li>
                  </ul>
                  <ul className="mr-3 my-2">
                    <li className="link">
                      <Link to="https://invideo.io/make/youtube-video-editor/">
                        YouTube Video Editor
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/slideshow-maker/">
                        Slideshow Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/outro-maker/">
                        Outro Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/invitation-maker/">
                        Invitation Maker
                      </Link>
                    </li>
                    <li className="link">
                      <Link to="https://invideo.io/make/movie-maker/">
                        Movie Maker
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-xl-2">
              <div className="widget">
                <h4>RESOURCES</h4>
                <div>
                  <ul className="my-2">
                    <li>
                      <Link to="https://invideo.io/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/copyright">Copyright Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterCopyRight className="copyright-content">
        <div className="container">
          <div className="copyright-text text-center flex justify-evenly">
            <div>
              <button
                type="button"
                onClick={redirectToLang}
                className="foooter__flag mx-2"
              >
                <img src="/flags/us.svg" alt="US" data-lang="en" />
              </button>
              <button
                type="button"
                onClick={redirectToLang}
                className="foooter__flag mx-2"
              >
                <img src="/flags/fr.svg" alt="FR" data-lang="fr" />
              </button>
              <button
                type="button"
                onClick={redirectToLang}
                className="foooter__flag mx-2"
              >
                <img src="/flags/in.svg" alt="IN" data-lang="in" />
              </button>
              <button
                type="button"
                onClick={redirectToLang}
                className="foooter__flag mx-2"
              >
                <img src="/flags/th.svg" alt="TH" data-lang="th" />
              </button>
              <button
                type="button"
                onClick={redirectToLang}
                className="foooter__flag mx-2"
              >
                <img src="/flags/ae.svg" alt="AE" data-lang="ar" />
              </button>
            </div>
            <p className="uppercase">© {siteConfig.siteName} 2020</p>

            <div>
              <a className="text-white m-2" href="/pricing">
                {t('pricing')}
              </a>
              <a className="text-white px-2" href="/privacy">
                {t('privacy_policy')}
              </a>
              {/* <Link className="text-white px-2" to="/terms">
                Terms And Conditions
              </Link> */}
            </div>

            {/* <div className="social-icons social-icons-colored social-icons-rounded">
              <ul className="social-links flex">
                <li className="social-tumblr">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Tumblr"
                    href="https://videocreek.tumblr.com"
                  >
                    <FontAwesomeIcon icon={faTumblr} />
                  </a>
                </li>
                <li className="social-facebook">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Facebook"
                    href="https://www.facebook.com/VideoCreek"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className="social-twitter">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Twitter"
                    href="https://twitter.com/VideoCreek"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li className="social-youtube">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    aria-label="Youtube"
                    href="https://www.youtube.com/channel/UCfuN-2w5NJNEoT5i6iUZO2Q"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </FooterCopyRight>
    </FooterContainer>
  );
}

export default Footer;
